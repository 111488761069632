/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/lodash@4.17.21/lodash.min.js
 * - /npm/html5shiv@3.7.3/dist/html5shiv.min.js
 * - /npm/html5shiv@3.7.3/dist/html5shiv-printshiv.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
